import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//componentes
import { PointToPointComponent } from './Pages/point-to-point/point-to-point.component';
import { ListVehicleComponent } from './Pages/list-vehicle/list-vehicle.component';
import { ListArticleComponent } from './Pages/list-article/list-article.component';
import { ConfirmArticlesComponent } from './Pages/confirm-articles/confirm-articles.component';
import { SummaryComponent } from './Pages/summary/summary.component';
import { DriversConfirmationComponent } from './Pages/drivers-confirmation/drivers-confirmation.component';
import { ThankyouComponent } from './Pages/thankyou/thankyou.component';
import { CancelServiceBeforeExitGuard } from './guards/CancelServiceBeforeExit/cancel-service-before-exit.guard';

const routes: Routes = [
  { path: '', component: PointToPointComponent },
  { path: 'list-vehicle', component: ListVehicleComponent },
  { path: 'list-article', component: ListArticleComponent },
  { path: 'confirm-articles', component: ConfirmArticlesComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'drivers-confirmation', 
    component: DriversConfirmationComponent, 
    canDeactivate: [CancelServiceBeforeExitGuard] 
  },
  { path: 'Thankyou', component: ThankyouComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
