<app-header></app-header>

<div class="ContenedorconfirmArticles">
    <div class="row mtop-negative">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 titulocontenedor">
                    <p class="titulo"><span>COTIZACIÓN POR ARTÍCULOS</span></p>
                </div>

                <div class="col-md-3 botonAgregarmas">
                    <button type="button" class="btn waves-effect btnCargarMas btn-rounded" routerLink="/list-article">
            <p>
              Agregar más Artículos
              <span class="fa fa-plus-circle iconomas"></span>
            </p>
          </button>
                </div>
                <div class="col-md-3 coldBtnConfirmar">
                    <button type="button" class="
              btn
              waves-effect waves-light
              btn-rounded btn-warning btn-small
              btnConfirmar
            " routerLink="/summary">
            <p><span>CONFIRMAR PEDIDOS</span></p>
          </button>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <app-loader class="loader" *ngIf="loader === true"></app-loader>
    <br /><br />

    <div class="row">
        <div class="col-md-6" *ngFor="let sub of articles">
            <div class="card card-kg">
                <div class="card-body">
                    <h4 class="color-orange subtitulo">{{ sub.nombre }}</h4>
                    <hr />
                    <div class="row">
                        <div class="col-md-4 imagenPrincipal">
                            <!--Cargar imagen de una articulo. Cambia la ruta de la imagen alojada en www.kamgus.com/ por la imagen alojada en invitados.kamgus.com/ -->
                            <img [src]="sub.imagen.replace('www.kamgus.com/img', 'invitados.kamgus.com/assets/img')" style="height: 120px" />
                        </div>
                        <div class="col-md-8">
                            <!--
                <div class="form-group">
                  <select class="form-control"
                  (change)="guardar( $event.target.value )">
                      <option>Selector de articulos</option>
                      <option *ngFor="let article of subCategories"

                      [value]="[article.id]"
                      > {{ article.name }}</option>
                  </select>
                </div>
                -->
                            <div class="row" *ngFor="let item of sub.items; let i = index">
                                <div class="col-md-8">
                                    <div class="row detalles">
                                        <div class="col-md-9 detallesTexto texto">
                                            <img class="imagenIcono" [hidden]="item.img == ''" [src]="item.img" style="width: 22px; height: 22px" />
                                            <!-- <img [src]="item.img" style="width: 22px; height: 22px" /> -->
                                            <p  [hidden]="item.nombre == ''" >{{ item.nombre }}</p>
                                            <p  [hidden]="item.name == ''" >{{ item.name }}</p>
                                        </div>
                                        <div class="col-md-3 detallesCantidad">
                                            <strong class="color-blue" style="text-decoration: underline">
                        {{ item.cantidad }}</strong
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-center">
                  <div class="row SumaResta">
                    <div class="col-md-4 click">
                      <span
                        class="fa fa-plus-circle color-blue"
                        (click)="addQuatity(item)"
                      ></span>
                    </div>
                    <div class="col-md-4 click">
                      <span
                        class="fa fa-minus-circle color-blue"
                        (click)="removeQuatity(item)"
                      ></span>
                    </div>
                    <div class="col-md-4 click">
                      <span
                        class="fa fa-trash-o color-blue"
                        (click)="delete(item)"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="row">
    <div class="col-md-6"
    *ngFor="let sub of articles">

      <div class="card card-kg">
        <div class="card-body">
          <h4 class="color-orange subtitulo">{{sub.name}}</h4>
          <hr>
          <div class="row">
            <div class="col-md-4">
             <img [src]="sub.url_imagen" style="height: 120px;">
            </div>
            <div class="col-md-8">

              <div class="form-group">
                <select class="form-control" >
                    <option>Selector de articulos</option>
                    <option
                    *ngFor="let article of articles"

                    > {{ article.name }}</option>
                </select>
              </div>

              <div class="row itemnombre"
              *ngFor="let item of articlesSelected; let i = index"
              [hidden]="sub.id !== item.sub_category_id">
                <div class="col-md-8">
                  <div class="row rowresp">
                    <div class="col-md-9 rowrespimg">
                      <img [src]="item.url_imagen" style="width: 22px; height: 22px;">
                      {{ item.name }}
                    </div>
                    <div class="col-md-3 rowrespnum">
                      <strong
                        class="color-blue"
                        style="text-decoration: underline"
                      >
                      {{ item.cantidad }}</strong
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-center">
                  <div class="rowiconos">
                    <div class="col-md-4 click">
                      <span class="fa fa-plus-circle color-blue" ></span>
                    </div>
                    <div class="col-md-4 click">
                      <span class="fa fa-minus-circle color-blue" ></span>
                    </div>
                    <div class="col-md-4 click">
                      <span class="fa fa-trash-o color-blue" ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <app-footer *ngIf="loader === false"></app-footer>
</div>

<!--

-->
