<app-header></app-header>

<div class="ContenedorlistArticle">
    <div class="row">
        <div class="contenedor1">
            <p class="titulo"><span>COTIZACIÓN POR ARTÍCULOS</span></p>
            <!--BUSCAADOR-->
            <!--<app-buscador (search)="handleSearch($event)"></app-buscador>-->

            <form autocomplete="off" (submit)="buscar()" class="buscarListarticle">
                <input type="text" class="buscarTexto" placeholder="Buscar Artículo" name="termino" [(ngModel)]="termino" (input)="inputValidator($event)" />
                <a class="botonListarticle">
                  <i class="fa fa-search"></i>
                </a>
            </form>

            <!--BUSCADOR FIN-->
        </div>

        <div class="col-md-12 contenedorBtnPrincipal">
            <div class="card card-kg">
                <div class="card-body">
                    <div class="row rowbtn">
                        <div class="col-md-4 btnLeft">
                            <button type="button" class="btn btn-rounded mb-2 btn1" data-toggle="modal" data-target="#addCustomize">
                <span>AGREGAR ARTÍCULO PERSONALIZADO</span>
              </button></div>
              <div class="col-md-2 btnLeft">
              <button type="button" class="btn btn-rounded btn1" (click)="cotizar()">
                <span>COTIZAR POR VEHICULO</span>
              </button>
                        </div>
                        <!--Boton Responsive-->
                        <div class="col-md-6 btnLeft ocultar2">
                            <button type="button" class="
                  btn
                  waves-effect waves-light
                  btn-rounded btn-secondary
                  btn1responsive
                " [disabled]="quantityCart === 0" (click)="next()">
                <span>ENVIAR PEDIDO</span>
              </button>
                        </div>
                        <!--Boton Responsive-->

                        <div class="col-md-4 text-right">
                            <button type="button" class="
                  btn
                  waves-effect waves-light
                  btn-rounded btn-warning btn-small
                  btn2
                  ocultar
                " [disabled]="quantityCart === 0" (click)="next()">
                <span>ENVIAR PEDIDO</span>
              </button>
                        </div>
                        <div class="iconoTruck" (click)="next()">
                            <div class="col-md-1 text-right">
                                <span class="fa fa-truck color-orange kart"></span>
                            </div>
                            <div class="col-md-1 text-left">
                                <div class="cart-items">
                                    {{ quantityCart }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Categorias-->

    <div class="wrapper">
        <div class="text-center cats" *ngFor="let cat of categories" (click)="cambiarCategoria(cat.id)">
            <!--Cargar imagen de una categoria. Cambia la ruta de la imagen alojada en www.kamgus.com/ por la imagen alojada en invitados.kamgus.com/ -->
            <img [src]="cat.imagen.replace('www.kamgus.com/img', 'invitados.kamgus.com/assets/img')" class="img-categories cursor" [ngClass]="{ 'img-categoriesActive': cat.id == idCategoria }" />
            <h4>{{ cat.nombre }}</h4>
        </div>
    </div>

    <!--Categorias Telefono
[ngClass]="{'img-categoriesActive':cat.id==idCategoria}"
-->

    <div *ngIf="loader === false" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <!--style="background-image:url(../../../../assets/images/CategoriaArticulos/SalaComedor.png)"-->
            <div class="carousel-item active" (click)="cambiarCategoriaMovil('1')">
                <img class="d-block w-100" src="../../../../assets/images/CategoriaArticulos/SalaComedor.png" alt="First slide" />
                <h4 class="text-center">Sala y Comedor</h4>
            </div>

            <div class="carousel-item" (click)="cambiarCategoriaMovil('2')">
                <img class="d-block w-100" src="../../../../assets/images/CategoriaArticulos/dormitorio.png" alt="First slide" />
                <h4 class="text-center">Dormitorio</h4>
            </div>

            <div class="carousel-item" (click)="cambiarCategoriaMovil('3')">
                <img class="d-block w-100" src="../../../../assets/images/CategoriaArticulos/cocina.png" alt="First slide" />
                <h4 class="text-center">Cocina</h4>
            </div>

            <div class="carousel-item" (click)="cambiarCategoriaMovil('4')">
                <img class="d-block w-100" src="../../../../assets/images/CategoriaArticulos/exteriores.png" alt="First slide" />
                <h4 class="text-center">Exteriores</h4>
            </div>

            <div class="carousel-item" (click)="cambiarCategoriaMovil('5')">
                <img class="d-block w-100" src="../../../../assets/images/CategoriaArticulos/construccion.png" alt="First slide" />
                <h4 class="text-center">Construcción</h4>
            </div>
        </div>

        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
    <!--
  selectSubCategories
-->
    <!--Categorias Fin-->
    <br class="ocultar" />
    <br class="ocultar" />

    <div class="row cardResponsive">
        <app-loader *ngIf="loader === true"></app-loader>
        <!--CARD 1-->
        <div class="col-md-6" *ngIf="customArticles !== undefined && customArticles.length > 0 && hasCustomInThisCategory()">
            <div class="card card-kg">
                <div class="card-body">
                    <h4 class="color-orange subtitulo">Personalizado</h4>
                    <hr />
                    <div class="row">
                        <div class="col-md-4">
                            <img src="https://app.kamgus.com/assets/images/ICONOS-15.png" style="height: 120px" />
                        </div>
                        <div class="col-md-8">
                            <div class="row itemnombre" *ngFor="let item of customArticles; let i = index">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-9">
                                            {{ item.nombre || item.name }}
                                        </div>
                                        <div class="col-md-3">
                                            <strong class="color-blue" style="text-decoration: underline">
                                                {{ item.cantidad }}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div class="rowiconos">
                                        <div class="col-md-4 click iconoslist">
                                            <span class="fa fa-plus-circle color-blue circulo" (click)="addQuatityCustom(item)"></span>
                                        </div>
                                        <div class="col-md-4 click iconoslist">
                                            <span class="fa fa-minus-circle color-blue circulo" (click)="removeQuatityCustom(item)"></span>
                                        </div>
                                        <div class="col-md-4 click iconoslist">
                                            <span class="fa fa-trash-o color-blue" (click)="delete(item)"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--CARD 1 FIN-->
        <!--Cards 2-->

        <!--<ng-container *ngIf="( subCategories |  search:filtro_valor ) as result">
 [hidden]="sub.id == idSubcategoria"
   -->
        <!--<h1 *ngIf="result.length === 0 && loader===false " class="SinResultado">No se encontraron resultados</h1>-->
        <div class="col-md-6" *ngFor="let sub of subCategories; let i = index" [hidden]="sub.id == idSubcategoria">
            <div class="card card-kg">
                <div class="card-body">
                    <h4 class="color-orange subtitulo">{{ sub.nombre }}</h4>
                    <hr />
                    <div class="row">
                        <div class="col-md-4 imagenCategories">
                            <!--Cargar imagen de una categoria. Cambia la ruta de la imagen alojada en www.kamgus.com/ por la imagen alojada en invitados.kamgus.com/ -->
                            <img [src]="sub.imagen ? sub.imagen.replace('www.kamgus.com/img', 'invitados.kamgus.com/assets/img') : sub.imagen" style="height: 120px" />
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <form [formGroup]="miFormulario">
                                    <select class="form-control" formControlName="data" (change)="guardar($event)">
                                        <option value="">Selector de articulos</option>
                                        <option
                                        *ngFor="let article of filterArticles(sub.id)"
                                        [value]="[article.id]"
                                        >
                                        {{ article.nombre }}
                                        </option>
                                    </select>
                                </form>
                            </div>

                            <!--Parte 2-->
                            <div class="row itemnombre" *ngFor="let item of articlesSelected; let i = index" [hidden]="sub.id !== item.id_articulo_acarreo_2">
                                <div class="col-md-8 textoCantidad">
                                    <div class="row RowtextoCantidad">
                                        <div class="col-md-9 texto">
                                            <img [src]="item.img" style="width: 22px; height: 22px" />
                                            <p>{{ item.nombre }}</p>
                                        </div>
                                        <div class="col-md-3 form-group cantidad">
                                            <input type="text" class="form-control p-0 h-auto color-blue txt-cantidad" style="text-decoration: underline" [(ngModel)]="item.cantidad" (change)="changeQuatity(item.cantidad, i)"/>
                                                
                                            

                                            <!--
                       {{ cantidadArticulos }}
                       (click)="cantidadArticulos= cantidadArticulos + 1"
                    -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center contenedorIconos">
                                    <div class="rowiconos">
                                        <div (click)="addQuatity(i)" class="col-md-4 click">
                                            <span class="fa fa-plus-circle color-blue iconoResponsive"></span>
                                        </div>

                                        <div (click)="removeQuatity(i)" class="col-md-4 click">
                                            <span class="fa fa-minus-circle color-blue iconoResponsive"></span>
                                        </div>
                                        <div class="col-md-4 click">
                                            <span (click)="delete(item)" class="fa fa-trash-o color-blue iconoResponsive"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--</ng-container>-->
        <!--sinner
  <div class="row pb-5">
    <div class="cold-md-12 p-5">
      <ngx-spinner
      bdOpacity = 0.9
      bdColor= ''
      size="default"
      color="red"
      type="ball-pulse-sync"
      [fullScreen]="false"
      >

      </ngx-spinner>
    </div>
    </div> -->
        <!--CARDS 2 FIN-->
    </div>
    <div class="modal fade contModal" id="addCustomize" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel1">NUEVO ARTICULO</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <form class="row rowform" *ngIf="formData" [formGroup]="formData">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input autocomplete="none" type="text" class="form-control" placeholder="Nombre del producto" formControlName="name" />
                                <div *ngIf="f.name.status === 'INVALID' && f.name.touched" class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <select class="form-control" placeholder="Categoria" formControlName="categoria">
                  <option value="">Seleccione una categoria</option>
                  <option *ngFor="let c of categories" [value]="c.id">
                    {{ c.nombre }}
                  </option>
                </select>
                                <div *ngIf="
                    f.categoria.status === 'INVALID' && f.categoria.touched
                  " class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea autocomplete="none" type="text" class="form-control" placeholder="Descripción" formControlName="descripcion"></textarea>
                                <div *ngIf="
                    f.descripcion.status === 'INVALID' && f.descripcion.touched
                  " class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input autocomplete="none" type="text" class="form-control" placeholder="Alto (cms)" formControlName="alto" onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;" />
                                <div *ngIf="f.alto.status === 'INVALID' && f.alto.touched" class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input autocomplete="none" type="text" class="form-control" placeholder="Largo (cms)" formControlName="largo" onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;" />
                                <div *ngIf="f.largo.status === 'INVALID' && f.largo.touched" class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input autocomplete="none" type="text" class="form-control" placeholder="Ancho (cms)" formControlName="ancho" onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;" />
                                <div *ngIf="f.ancho.status === 'INVALID' && f.ancho.touched" class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input autocomplete="none" type="text" class="form-control" placeholder="Peso (lbs)" formControlName="peso" onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;" />
                                <div *ngIf="f.peso.status === 'INVALID' && f.peso.touched" class="invalid">
                                    *campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 modalbtnAgregar">
                            <button type="button" class="
                  btn
                  waves-effect waves-light
                  btn-rounded btn-warning btn-small
                  btnAgregar
                " data-dismiss="modal" [disabled]="
                  f.peso.status === 'INVALID' ||
                  f.ancho.status === 'INVALID' ||
                  f.largo.status === 'INVALID' ||
                  f.alto.status === 'INVALID' ||
                  f.categoria.status === 'INVALID' ||
                  f.descripcion.status === 'INVALID' ||
                  f.name.status === 'INVALID'
                " (click)="addItem()">
                <!--(click)="addItem()"-->
                Agregar producto
              </button>
                            <div *ngIf="
                  f.peso.status === 'INVALID' ||
                  f.ancho.status === 'INVALID' ||
                  f.largo.status === 'INVALID' ||
                  f.alto.status === 'INVALID' ||
                  f.categoria.status === 'INVALID' ||
                  f.name.status === 'INVALID'
                " class="invalid text-center mt-4">
                                *Todos los Campos son Obligatorios
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <app-footer *ngIf="loader === false"></app-footer>
</div>
