import { NavigationService } from '../../../../src/app/services/Navigation/navigation.service';
import { StripeService } from '../../../../src/app/services/StripeService/stripe-service.service';
import { PaymentCustomer, ServiceParameters } from '../../../../src/app/Interfaces/ServiceParameters';
import { options } from './../../app.module';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../servicesApi/api/api.service';
import { SharedService } from '../list-vehicle/SharedService/shared.service';
import { dataURLToBlob, resizeImage } from '../../../../src/app/Helpers/resize_image';
import { environment } from '../../../environments/environment';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControlName,
} from '@angular/forms';
import { timer } from 'rxjs';

/*Mapa temporal*/


declare var google: any;
/** Para el MODAL Precio Sugerido aperture automaticamente */
declare var jQuery:any;
/** ****** */
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  providers: [SharedService, ApiService],
})
export class SummaryComponent implements OnInit {

  /*phoneMask = ['(', '+', 5, 0, 7, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ,/\d/]*/
  precio: string = "";
  service: ServiceParameters | any | null = {};
  addDatos: any;
  lat: any = 9.0855953;
  lng: any = -79.3865497;
  origin: any = [];
  destination: any = [];
  zoom = 12;
  options = {
    componentRestrictions: { country: 'PA' },
  };
  user: any = {};
  cards = [];
  cardSelected: any = {};
  articles: any = [];
  tiempoCargaYDescargue = 0;
  totalPriceCart = 0;

  metroscubicos = 0;
  capacidadTon = 0;
  arrayVehi: any;
  totalPrice = 0;
  totalPriceS = 0;
  descripcion = '';
  fechaConvertida = '';
  estado = '';
  servicioActivo = [];
  PRE_AUTORIZATION = '';
  //ModificarRuta
  ruta: Boolean = false;
  //Descriccion
  telefono: string = '';
  /*Precio Sugerido*/
  emailRegistrado = false;
  rayas = true;
  PrecioSugerido: any;
  precioSugerido: any;
  dangerPRECIO: Boolean = false;
  vehicle_nombre = '';
  
  /* Cotización por Vehiculo */
  typeVehicle: boolean = false;
  selVehicle: any = '';
  //Tiempo Carga o Descarga
  tiempoC = 0;

  /* Capacidad de Carga aceptable */
  cargaAcept  : boolean =  false;

  public loginUrl: string = 'https://app.kamgus.com/continue_making_service';
  public loginParams: string = '';

  public previewImages: any[] = [];
  //sugerirPrecios:boolean = false;

  formDataPreciosg: FormGroup = this.formBuilder.group({
    PrecioSugerido: ['', [Validators.required, Validators.min(0)]],
  });

  /*Formulario Final*/

  imfoInvitado: FormGroup = this.formBuilder.group({
    nombre: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    apellido: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    correo: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required]],
    clave: [''],
    claveConfirm: [''],
    descripcion: ['', [Validators.required]],
    condiciones: [false, Validators.requiredTrue],
    crearCuenta: false,
    assistant: 0,
    image_description: null

    //telefono: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(8)], ],
  });
  //get f() { return this.imfoInvitado.controls; }

  /*Formulario Final Articulos*/

  imfoInvitadoArticulos: FormGroup = this.formBuilder.group({
    nombre: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    apellido: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    correo: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required]],
    clave: [''],
    claveConfirm: [''],
    descripcion: [''],
    condiciones: [false, Validators.requiredTrue],
    crearCuenta: false,
    //PrecioSugerido: ['', [Validators.required , Validators.min(0)]]
  });
  /**/

  /*  Tarjeta de credito */
  //Validators.pattern(/^[0-9 +]+$/)

  /**/
  stripeClientSecret: any;
  public card_number: string = "";
  constructor(
    private sharedService: SharedService,
    private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private _sh: SharedService,
    private navS: NavigationService
  ) {
    this.stripeClientSecret = {};
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.service = JSON.parse(localStorage.getItem('service') || "{}");
    const precioS = JSON.parse(localStorage.getItem("precio") || "{}").PrecioSugerido || null;
    const storedSugestedPrice = {      
      PrecioSugerido: precioS,
    };
    this.formDataPreciosg.patchValue(storedSugestedPrice);
    this.refreshPrecioSugerido(storedSugestedPrice);
    
    let clientData = { };  
    if(this.service.options.client){        
      clientData ={      
        nombre: this.service.options.client.nombre || '',
        apellido: this.service.options.client.apellido || '',
        correo: this.service.options.client.correo || '',
        telefono: this.service.options.client.telefono || '',
        clave: this.service.options.client.clave || '',
      };
    }
    this.imfoInvitado.patchValue(clientData);    
    this.imfoInvitadoArticulos.patchValue(clientData);

    //if (localStorage.getItem('typeVehicle')) {
    if (this.service.options.typeVehicle=='vehiculo') {
        
        this.imfoInvitado.patchValue({      
          descripcion: this.service.options.description || '',
          assistant: this.service.options.assistant || 0,  
        });
        this.cotizaVehicle();
    } else {
      this.loadArticles();
    }
    this.loadLocations();
    /** MODAL Precio Sugerido abrir automaticamente */
    jQuery("#sugerirPrecio").modal('show');
    /** ****** */
    localStorage.removeItem('secondTime');
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    
  }
  /* Cotizacion por Vehiculo */
  cotizaVehicle() {
    console.log('------- Cotiza por vehiculo ------------');
    this.typeVehicle = JSON.parse(localStorage.getItem('typeVehicle') || "{}");

    console.log('Variable typevehicle ----> ', this.typeVehicle);

    const timeMaps = parseInt(this.service.time.duration.value) / 60;
    console.log('Tiempo recorrido en min (t)-------- ', timeMaps);

    this.selVehicle = JSON.parse(localStorage.getItem('selVehicle') || "{}");

    const pricePerMin = parseFloat(this.selVehicle['precio_minuto']); //this.arrayVehi[0]['precio_minuto'];
    console.log('Precio por Minuto (PV) -----', pricePerMin);

    const priceAyu = parseFloat(this.selVehicle['precio_ayudante']);
    console.log('Precio Ayudante -------', priceAyu);

    const pk = this.selVehicle['pk'];
    console.log('Precio por kilometraje (pk)---------', pk);

    const k = parseFloat(this.service.time.distance.value) / 1000;
    console.log('Distancia en kilometros (k)---------', k);

    const ts = parseFloat(this.selVehicle['tiempo']);
    console.log('Tiempo de servicio (ts)-------- ', ts);

    this.vehicle_nombre = this.selVehicle['nombre_camion'];
    console.log('Nombre Vehiculo -------', this.vehicle_nombre);

    this.totalPrice = (timeMaps + ts * 60) * pricePerMin + k * pk + priceAyu;
    console.log('TOTAL Price -------------, ', this.totalPrice.toFixed(2));

    // Descripción del artículo
    const DatosUsuario = this.imfoInvitado.value;
    this.descripcion =  DatosUsuario.descripcion;
    console.log('Descripción --------- ' ,this.descripcion);

  }

  loadArticles() {
    this.articles = JSON.parse(localStorage.getItem('articles') || "{}");
    console.log('ARTICULOS -- ', this.articles);

    this.calculatePricingForArticles();
  }

  loadLocations() {
    this.lat = this.service.coords.lat;
    this.lng = this.service.coords.lng;
    this.origin = this.service.location.origin;
    this.destination = this.service.location.destination;
  }
  changeMethodPay(method: string) {
    this.service.options.typePay = method;
    //if(method != "credito"){
      localStorage.setItem('service', JSON.stringify(this.service)); 
    //}
    //console.log(this.service.options)
  }

  selCard(card: any) {
    this.cardSelected = card;
    //console.log(this.cardSelected);
  }
  cancel() {
    this.router.navigate(['/services/point-to-point']);
  }
  goToAddMoreArticles(){
    this.router.navigate(['/list-article']);
  }
  /** Cacula precio por Articulos */
  calculatePricingForArticles() {
    console.log('Articulos ----------------', this.articles);

    if (this.articles !== null) {
      let m3 = 0;
      let tc = 0;
      let price = 0;
      for (let i = 0; i < this.articles.length; i++) {
        let altura = parseFloat(this.articles[i].altura) / 100;
        let ancho = parseFloat(this.articles[i].ancho) / 100;
        let largo = parseFloat(this.articles[i].largo) / 100;
        let cantidad = parseFloat(this.articles[i].cantidad);
        m3 += parseFloat(((altura * ancho * largo) * cantidad).toFixed(2));
        console.log('M3 ------',m3,  '<<<<<<<<<<<<<<<<<<');
        tc +=
          parseInt(this.articles[i].tc) * parseInt(this.articles[i].cantidad);
          console.log("TC DE CADA ARTICULO ---- ",tc);
          price += m3 * this.articles[i].price;
        // console.log(this.articles[i])
      }
      // Tiempo de servicio (ts)
      this.tiempoCargaYDescargue = tc;
      // 'Precio por kilometraje (pk)
      this.totalPriceCart = price; //precio minto por la cantidadidad
      this.metroscubicos = m3;
      this.mudanza();
      const DatosUsuario = this.imfoInvitado.value;
      DatosUsuario.descripcion = 'Sin Descripción Pedido Articulo';
      this.descripcion = 'Sin Descripción Pedido Articulo';
    }
  }
  /** ******** */

  /*calculatePricing() {
    console.log('Articulos ----------------', this.articles);
    if (this.articles !== null) {
      let price = 0;
      let m3 = 0;
      let cap = 0;
      for (let i = 0; i < this.articles.length; i++) {
        price += this.articles[i].cantidad * this.articles[i].price;
        this.tiempoC += this.articles[i].cantidad * this.articles[i].tc;
        m3 +=
         ( this.articles[i].cantidad *
          ((parseFloat(this.articles[i].altura) / 100) *
            (parseFloat(this.articles[i].ancho) / 100) *
            (parseFloat(this.articles[i].largo) / 100)));
        cap +=
          this.articles[i].cantidad *
          parseFloat(this.articles[i].capacidad_peso);
        console.log('>>>>>>>', this.articles[i]);
        // console.log(this.articles[i].capacidad_peso,  '<<<<<<<<<<<<<<<<<<')
        console.log('M3 ------',m3,  '<<<<<<<<<<<<<<<<<<');
      }

      this.tiempoCargaYDescargue = price;
      this.totalPriceCart = price; //precio minto por la cantidadidad

      this.metroscubicos = m3;
      this.capacidadTon = cap;
      this.mudanza_();
      // this.mudanza();
      const DatosUsuario = this.imfoInvitado.value;
      DatosUsuario.descripcion = 'Sin Descripción Pedido Articulo';
    }
  }
  */

  /** Calculo final por la MUDANZA tomando en cuenta cada ARTICULO */
  mudanza() {
    this.apiService
      .genericGet(
        //'https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getTrucksInvitado'
        `${environment.endpoints.vehicle.base}`
      )
      .subscribe((resp: any) => {
        let id;

        this.arrayVehi = {
          nombre_camion: 'NO EXISTE CAPACIDAD',
          precio_minuto: '0',
          precio_ayudante: 0,
          tc: 0,
          pk: 0
        };

        for (const i in resp.trucks) {

          if (this.metroscubicos < parseFloat(resp.trucks[i]['capacidad'])) {
            this.arrayVehi = resp.trucks[i];
            console.log(resp.trucks[i]);

            this.cargaAcept = false;
            console.log("Var CARGA ACEPTADA cargaAcept---> ", this.cargaAcept);

            break;
          }
          else {
            this.cargaAcept = true;
            console.log("Var CARGA ACEPTADA cargaAcept---> ", this.cargaAcept);

          }
        }

        const timeMaps = parseInt(this.service.time.duration.value) / 60;
        const pricePerMin = parseFloat(this.arrayVehi['precio_minuto']); //this.arrayVehi[0]['precio_minuto'];
        const priceAyu = parseFloat(this.arrayVehi['precio_ayudante']);
        const ts = this.arrayVehi['tc'];
        const pk = this.arrayVehi['pk'];

        const k = parseFloat(this.service.time.distance.value) / 1000;
        this.totalPrice =
          (timeMaps + this.tiempoCargaYDescargue) * pricePerMin +
          k * pk +
          priceAyu;
        this.arrayVehi['precioTotal'] = this.tiempoCargaYDescargue;
        this.service.vehicle = this.arrayVehi;
        this.vehicle_nombre = this.arrayVehi['nombre_camion'];

        console.log('TOTAL Price -------------, ', this.totalPrice.toFixed(2));
        console.log('Tiempo recorrido en min (t)-------- ', timeMaps);

        console.log('Tiempo de servicio (ts)-------- ', ts);
        console.log(
          'Tiempo de carga y descarga (tc)-------- ',
          this.tiempoCargaYDescargue
        );
        console.log('Distancia en kilometros (k)---------', k);
        console.log('Precio por kilometraje (pk)---------', pk);
        console.log('Precio por Minuto (PV) -----', pricePerMin);
        console.log('Precio Ayudante -------', priceAyu);
        console.log('Metros Cubicos ---- ', this.metroscubicos.toFixed(2));
        console.log('Tipo Vehiculo ----', this.vehicle_nombre);
        console.log('Capacidad Vehiculo ---- ',this.service.vehicle['capacidad']);

      });
  }
  /** ********** */

/*
  mudanza_() {
    this.apiService
      .genericGet(
        'https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getTrucks'
      )
      .subscribe((ok: any) => {
        this.arrayVehi = ok.trucks;
        let id;

        this.arrayVehi = {
          nombre_camion: 'NO EXISTE CAPACIDAD',
          precio_minuto: '0',
          precio_ayudante: 0,
          tc: 0,
          pk: 0
        };

        for (const i in this.arrayVehi) {

          if (this.metroscubicos < parseInt(this.arrayVehi[i]['capacidad'])) {
            id = this.arrayVehi[i]['id_tipo_camion'];
            this.cargaAcept = false;
            console.log("CARGA ACEPTADA ---> ", this.cargaAcept);

            break;

          }
          else{
            this.cargaAcept = true;
            console.log("CARGA ACEPTADA ---> ", this.cargaAcept);
          }
        }

        this.arrayVehi = this.arrayVehi.filter(
          (item) => item.id === id
        );

        const timeMaps = Math.round(
          parseInt(this.service.time.duration.value) / 60
        );


        const pricePerMin = parseFloat(this.arrayVehi[0]['precio_minuto']); //this.arrayVehi[0]['precio_minuto'];
        // console.log('Precio por Minuto (PV) -----', pricePerMin);
        const priceAyu = this.arrayVehi[0]['precio_ayudante'];
        // console.log('Precio Ayudante -------', priceAyu);

        // Tiempo de Servicio  TS = TC
        const ts = 5; //this.arrayVehi[0]['ts'];
        //Precio por kilometraje
        const pk = 0.5; //this.arrayVehi[0]['pk'];
        // console.log('Precio por kilometraje (pk)---------', pk);

        //Kilometraje recorrido
        const k = parseFloat(this.service.time.distance.value) / 1000;
        // console.log('kilometraje (k)-------------', k);

        // Formula para Vehículo
        //this.totalPrice = (timeMaps + ts * 60) * pricePerMin + k * pk + priceAyu;
        this.totalPrice =
          (timeMaps + this.tiempoC) * pricePerMin + k * pk + priceAyu;
        // console.log('TOTAL Price -------------, ', this.totalPrice);

        //this.totalPrice = timeMaps * pricePerMin + this.tiempoCargaYDescargue * pricePerMin + parseInt(priceAyu);
        // tslint:disable-next-line: radix

        this.arrayVehi[0].precioTotal = this.tiempoCargaYDescargue;
        this.service.vehicle = this.arrayVehi[0];
      });
  }
*/

  addQuatity(index: number) {
    //console.log( this.articles );
    this.articles[index].cantidad = this.articles[index].cantidad + 1;
    localStorage.setItem('articles', JSON.stringify(this.articles));
    this.loadArticles();
  }
  removeQuatity(index: number) {
    if (this.articles[index].cantidad > 1) {
      this.articles[index].cantidad = this.articles[index].cantidad - 1;
      localStorage.setItem('articles', JSON.stringify(this.articles));
      this.loadArticles();
    }
  }
  delete(article: any) {
    const tmp = this.articles.filter((item: any) => item.id !== article['id']);
    localStorage.setItem('articles', JSON.stringify(tmp));
    this.loadArticles();
  }

  /*Precio sugerido*/
  addPrecioSugerido() {
    if (this.formDataPreciosg.valid) {
      localStorage.setItem(
        'precio',
        JSON.stringify(this.formDataPreciosg.value)
      );

      this.refreshPrecioSugerido(this.formDataPreciosg.value);
      //this.formDataPreciosg.reset();

      const espera = timer(1000);

      espera.subscribe((n) => {
        this.ruta = true;
      });
      return;
    }
  }
  /*** ******** */

  /** Registrar Mudanza  */
  sendArticulos() { //crear un nuevo servicio
    if (this.imfoInvitadoArticulos.invalid) {
      console.log(this.imfoInvitadoArticulos)
      this.imfoInvitadoArticulos.markAllAsTouched();
      this.dangerPRECIO = true;
      return;
    } else {
      this.dangerPRECIO = true;
      const DatosUsuarioInvitado = this.imfoInvitadoArticulos.value;
      localStorage.setItem(
        'precio',
        JSON.stringify(this.formDataPreciosg.value)
      );
      let precio = parseFloat(this.precioSugerido.PrecioSugerido).toFixed(2);
      //
      localStorage.setItem('addDatos', JSON.stringify(this.imfoInvitado.value));
      const route = JSON.parse(localStorage.getItem('service') || "{}");
      //console.log(route);
      var date;
      date = new Date(route.options.date);
      date =
        date.getUTCFullYear() +
        '-' +
        ('00' + (date.getUTCMonth() + 1)).slice(-2) +
        '-' +
        ('00' + date.getUTCDate()).slice(-2) +
        ' ' +
        ('00' + date.getUTCHours()).slice(-2) +
        ':' +
        ('00' + date.getUTCMinutes()).slice(-2) +
        ':' +
        ('00' + date.getUTCSeconds()).slice(-2);

      const articulos = this.articles.map((a: any) => ({
        id: a.id,
        cantidad: a.cantidad,
        nombre: a.nombre,
        m3: a.m3,
        //m2: a.m2,
        peso: a.peso,
        //tiempo: a.tiempo,
        tc: a.tc,
      }));

      let body: any = {
        tiempo: route.time.duration.value,
        kms: parseFloat(route.time.distance.value) / 1000,
        fecha_reserva: (new Date(route.options.date)).toISOString(),
        //tipo_translado: this.service.vehicle.nombre_camion,
        tipo_servicio: this.selVehicle,
        id_tipo_camion: this.service.vehicle.id_tipo_camion,
        tipo_translado: this.service.options.typeVehicle,
        estado: route.options.typeProgram,
        //valor: this.totalPrice, //Envia el precio segun formula
        valor: this.precio, //Envia el precio sugerido del cliente
        precio_sugerido: this.totalPrice,
        pago: 'PENDIENTE',
        //descripcion: 'ARTICULOS',
        descripcion: this.descripcion,
        tipo_pago: this.service.options.typePay,
        tokenTarjeta: '-',

        punto_inicial: route.options.start,
        coordenas: JSON.stringify({
          coord_punto_inicio: `${route.location.origin.lat},${route.location.origin.lng}`,
          coord_punto_final: `${route.location.destination.lat},${route.location.destination.lng}`,
        }),
        latitud_inicial: route.location.origin.lat,
        longitud_inicial: route.location.origin.lng,
        punto_final: route.options.end,
        latitud_final: route.location.destination.lat,
        longitud_final: route.location.destination.lng,
        nombres: DatosUsuarioInvitado.nombre,
        apellidos: DatosUsuarioInvitado.apellido,
        telefono: DatosUsuarioInvitado.telefono,
        correo: DatosUsuarioInvitado.correo,
        password: DatosUsuarioInvitado.clave,        
        articulos: JSON.stringify(articulos),

        customer_id: this.service.options.paymentData ? (this.service.options.paymentData as PaymentCustomer).customer_id : null,
        payment_method: this.service.options.paymentData ? (this.service.options.paymentData as PaymentCustomer).payment_method : null,
      };
      const dataToSend = new FormData();
      for (let key in body) {
        dataToSend.append(key, body[key]);
      }
      if(this.typeVehicle){
        dataToSend.append("description", this.imfoInvitado.value.descripcion);
        dataToSend.append("assistant", (this.imfoInvitado.value.assistant == 1 ? "1" : "0"));
        if(this.imfoInvitado.value.image_description){
          this.imfoInvitado.value.image_description.map((imageDescription: any) => dataToSend.append("image_description[]", imageDescription));
          

        }
      }
      //console.log("SERVICIO VEHICLE ------>", this.service.vehicle);
      console.log("formulario dataToSend ------> ", body);


      this.apiService
        .genericPost(
          `${environment.endpoints.services.base}`,
          dataToSend
        )
        .subscribe(
          (respuesta: any) => {
            console.log('datos enviados');
            console.log('respuesta-----', respuesta);
            const id = respuesta.id;
            const precio_sugerido = respuesta.precio_sugerido;
            const data = respuesta.data;
            this.service.options.description = this.imfoInvitado.value.descripcion;
            this.service.options.assistant = this.imfoInvitado.value.assistant;
            this.service.options.client = {
              nombre: DatosUsuarioInvitado.nombre,
              apellido: DatosUsuarioInvitado.apellido,
              telefono: DatosUsuarioInvitado.telefono,
              correo: DatosUsuarioInvitado.correo,
              clave: DatosUsuarioInvitado.clave,
            }
            localStorage.setItem('service', JSON.stringify(this.service));    
            localStorage.setItem('id', id);
            localStorage.setItem('userId', respuesta.data[0].usuarios_id);
            localStorage.setItem('created_at_service', respuesta.data[0].creado);
            this.router.navigate(['drivers-confirmation']);
          },
          (err) => {
            console.log(err);
            if(err.status == 400 && err.error.error){
              this._sh.showError(err.error.msg);
              return;
            }
            if(err.status == 422 && err.error.error){
              const invalidFields = Object.keys(err.error.error);
              invalidFields.forEach(field => {
                this._sh.showError(err.error.error[field][0]);
              });
              return;
            }
            this.emailRegistrado = true;
            const emptyVal: URLSearchParams = new URLSearchParams('');
            Object.keys(body).map(parm => {emptyVal.set(parm, body[parm]);});

            this.loginParams = emptyVal.toString();

          }
        );
    }
  }
  getPaymentMethod(id: string = "", customerId: string){
      let url = 'https://www.api.kamgus.com/usuario-v2/index.php/StripeCustomer/payment_methods_invitado/'+id+'?customer_id='+customerId;

      this.apiService
        .genericGet(url).subscribe((ok: any)=>{ 
          if(id.length > 0){
            this.card_number = ok.data[0].card.last4 ? "Terminada en  "+ok.data[0].card.last4 : "";

            
            return;
          }
          //this.cards = ok['cards'][0]['CreditCards'];
          //this.cards =  this.cards.filter( card=> card.Status == "Active");

      },err=>{
        //this._util.presentToastWithOptions( 'Error', err['msg']);
        console.log('Error', err['msg'], "Pago.getPaymentMethods")
        //this._util.loader.dismiss();
      });

  }
  beginAddCard(){
    //Initi setup intent to use in the next page
    if(!this.stripeClientSecret.customer_id){
      this.stripeService.initializeSetupIntent().subscribe((data: any)=>{
        this.stripeClientSecret = data;
      }, (err: any) => {
        console.log("Error guardando fcmToken: ", err);
      });
    }
  }
  onCardSaved(data: any){
    console.log(data);
    this.service.options.typePay = "credito";
    this.service.options.paymentData = data;
    this.service.options.paymentData.customer_id = this.stripeClientSecret.customer_id;
    //this.getPaymentMethod(data.payment_method, this.stripeClientSecret.customer_id);
    localStorage.setItem('service', JSON.stringify(this.service));    
    this.sharedService.showSuccess("Tarjeta agregada exitosamente");
  }
  /** ********** */

  cancelar() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z]*$/;
  }

  modificarRuta() {
    //this.router.navigate(['/']);
    this.router.navigate(['']);
  }

  atras() {
    const route = JSON.parse(localStorage.getItem('service') || "{}");

    if (route.options.typeVehicle == 'vehiculo') {
      this.router.navigate(['/list-vehicle']);
    } else {
      this.router.navigate(['/list-article']);
    }
  }
  showPreview(event: any) {
    this.previewImages = [];
    const files = (event.target as HTMLInputElement).files || [];
    console.log( files)
    
    // File Preview
    var resizedImages: any = [];
    for (let index = 0; index < files.length && index < 3; index++) {
      const file = files[index];  
      let reader = new FileReader(); // change var by let.
      reader.onload = (readerEvent) => {
          let image = new Image();
          image.onload = (imageEvent) => {
              // Resize the image
              let canvas = document.createElement('canvas'),
                  max_size = 1200,
                  width = image.width,
                  height = image.height;
              if (width > height) {
                  if (width > max_size) {
                      height *= max_size / width;
                      width = max_size;
                  }
              } else {
                  if (height > max_size) {
                      width *= max_size / height;
                      height = max_size;
                  }
              }
              canvas.width = width;
              canvas.height = height;
              if(canvas.getContext('2d')){
                canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
              }
              var dataUrl = canvas.toDataURL(file.type);
              resizedImages.push(dataURLToBlob(dataUrl));
              this.imfoInvitado.patchValue({
                //image_description: file
                image_description: resizedImages  
              });
              if(this.imfoInvitado.get('image_description')){
                this.imfoInvitado.get('image_description')!.updateValueAndValidity()

              }
          }
          image.src = readerEvent?.target?.result as string;
          this.previewImages.push(reader.result as string);
      }
      reader.readAsDataURL(file);
    }
  }
  cotizar(type = "articulo"){
    //this.service = JSON.parse(localStorage.getItem('service'));
    this.service.options.typeVehicle = type;
    //console.log("COTIZAR --- ",JSON.parse(localStorage.getItem('service')));
    localStorage.setItem('service', JSON.stringify(this.service));    
    this.router.navigate(['/list-article']);
  }
  refreshPrecioSugerido(value: any){
    this.precioSugerido = value;
    this.precio = this.precioSugerido.PrecioSugerido;
    this.rayas = false;
  }
}
