import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class SharedService {

  constructor( private toastr: ToastrService ) { }

  decodeJwt() {
    const token = localStorage.getItem('token');
  
  }
  calculatePriceByVehicule( totalTime: string, precio_minuto: number, precio_ayudante: string, tiempo: number ) {
    // tslint:disable-next-line: radix
    const timeMaps = Math.round( ( parseInt ( totalTime ) / 60) * 2);
    precio_minuto = Math.round(timeMaps * precio_minuto) + parseInt(precio_ayudante) + tiempo * 60 * precio_minuto;
    return precio_minuto;
  }

  showSuccess(msg: string, label: string = '¡Exitoso!') {
    this.toastr.success( msg, label, {
      timeOut: 2000,
    });
  }
  showError(msg: string, label: string = 'Error') {
    this.toastr.error(msg, label,  {
      timeOut: 2000,
    });
  }

}