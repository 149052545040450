
<div class="modalPedido">
    
         
        <!--CONTENIDO-->
        <div class="text-center contenedorPedido">
          <button
          type="button"
          class="close closebtn"
          data-dismiss="modal"
          aria-label="Close"
          onclick="location.href='https://kamgus.com.pa/app-acarreo-y-mudanza-panama/'"
          
        >
          <span aria-hidden="true">&times;</span>
        </button>
          <br /><br />
          <h1 class="color-orange titulo">GRACIAS POR TU PEDIDO</h1>
  
          <br />
          <i class="fa fa-shopping-bag"></i>
          <br />
          <br />
          <br />
          <br />
          <h3>Enviaremos actualizacion de pedido por email</h3>
          <h3 class="color-orange">SU PEDIDO ESTA EN PROCESO</h3>
          <h4>#{{id}}</h4>
          <br />
          <P class="textgracias"
            >MEJORA LA EXPERIENCIA AL UTILIZAR KAMGUS CON NUESTRAS APP MOVILES</P
          >
          <div class="rowrespondiveModal">
            <div class="col-md-6 text-right coldrespondiveModal">
              <a target="blank" href="https://play.google.com/store/apps/details?id=com.kamgusu.pa">
                <img
                  src="../../../assets/images/google.png"
                  style="height: 40px"
                />
              </a>
            </div>
            <div class="col-md-6 text-left coldrespondiveModal">
              <a  target="blank" href="https://apps.apple.com/us/app/id1495896386">
                
                <img src="../../../assets/images/ios.png" style="height: 40px" />
              </a>
            </div>
          </div>
          <br />
        </div>
    

  </div>
