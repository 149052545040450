<app-header></app-header>
<div class="ContenedorSummary">
  <div class="row top-bottoms">
    <div class="col-md-7 tituloprincipal">
      <button type="button" class="
          btn
          waves-effect waves-light
          btn-rounded btn-secondary
          btnRegresar
        " (click)="atras()">
        <i class="fa fa-arrow-left"></i> REGRESAR
      </button>
      <p class="mt-3">
        <span>COTIZACIÓN POR {{ service.options.typeVehicle }}</span>
      </p>
    </div>
    <!-- <div class="col-md-5 btnregresar">
      <button type="button"
      class="btn waves-effect waves-light btn-rounded btn-secondary " (click)="atras()">REGRESAR</button>
    </div>-->
  </div>
  <!--btn waves-effect waves-light btn-rounded btn-secondary-->
  <hr />

  <div class="row">
    <!--detalle del servicio-->
    <div class="col-md-6">
      <div class="card card-kg">
        <div class="card-body subtitulo detallesServ">
          <h4>DETALLES DEL SERVICIO</h4>
          <hr class="separadorsubtitulo" />
          <!--Fila1 a-->
          <div class="row mtop-botton coldresp">
            <div class="col-md-1 text-center iconoResp">
              <span class="fa fa-clock-o color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-6 textAling text1">
              <p class="color-blue">Tiempo aproximado recorrido</p>
            </div>
            <div class="col-md-5 textAling text2 textvehiculoduration">
              <h5>{{ service.time.duration.text }}</h5>
            </div>
          </div>
          <!--Fila1 b-->
          <div class="row mtop-botton coldresp">
            <div class="col-md-1 text-center iconoResp">
              <span class="fa fa-random color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-6 textAling text1">
              <p class="color-blue">Kilometraje aproximado</p>
            </div>
            <div class="col-md-5 textAling text2 textvehiculoNombre">
              <h5>{{ service.time.distance.text }}</h5>
            </div>
          </div>
          <!--Fila1 c-->
          <div class="row mtop-botton coldresp" *ngIf="articles === null">
            <div class="col-md-1 text-center iconoResp iconoDollar">
              <br />
              <span class="fa fa-dollar color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-6 textAling text1 PrecioSugerido">
              <br />
              <p class="color-blue">Precio sugerido</p>
            </div>
            <div class="col-md-5 textAling text2 usd divprecio textvehiculoNombre">
              <div class="contenedorPrecioIcono">
                <h5>
                  <strong *ngIf="rayas">- - - -</strong>
                  <strong>{{ precio }}</strong> USD
                  <span class="text-danger abvertenciaprecio" *ngIf="precio == null">*</span>
                </h5>
                <!--  textAling usd text2     -->
                <!--ICONOS vehiculos-->

                <div class="iconosPrecio" *ngIf="ruta">
                  <button *ngIf="
                      precio > service.vehicle.precioTotal * 0.7 &&
                      precio < service.vehicle.precioTotal * 0.86
                    " class="far fa-smile btnIconoprecio" style="font-size: 38px; border-color:transparent;"
                    type="button" data-toggle="modal" data-target="#iconoAmarillo"
                    [title]="'El precio sugerido esta un poco por debajo del promedio. Puede Solicitar un Kamgus sin Embargo puede no tener respuesta de ningun conductor'"></button>

                  <!--modal customize article-->
                  <!--Modales Caritas-->

                  <app-modales-iconos [value]="totalPrice.toFixed(0)"></app-modales-iconos>
                  <!--Fin Modal-->

                  <button *ngIf="precio >= service.vehicle.precioTotal * 0.86" class="far fa-laugh btnIconoprecio"
                    style="font-size: 38px; border-color:transparent;" type="button" data-toggle="modal"
                    data-target="#iconoVerde"
                    [title]="'El precio sugerido es acorde al promedio. Puede Solicitar un Kamgus es muy posible que un conductor acepte su servicio'"></button>
                  <app-modales-iconos2 [value]="totalPrice.toFixed(0)"></app-modales-iconos2>
                  <span *ngIf="precio <= service.vehicle.precioTotal * 0.7" class="far fa-frown btnIconoprecio"
                    style="font-size: 38px; border-color:transparent;" type="button" data-toggle="modal"
                    data-target="#iconoRojo"
                    [title]="'El precio sugerido esta muy por debajo del promedio.Puede Solicitar un Kamgus sin Embargo es muy posible no tener respuesta de ningun conductor'"></span>
                </div>

                <app-modales-iconos3 [value]="totalPrice.toFixed(0)"></app-modales-iconos3>
                <!--ICONOS-->
              </div>
              <button type="submit" class="btnSugerirPrecio" data-toggle="modal" data-target="#sugerirPrecio">
                <h6>SUGERIR PRECIO</h6>
              </button>
              <span class="text-danger ml-3 text-dangerPRECIO" *ngIf="precio == null && dangerPRECIO">
                *Campo Requerido
              </span>
            </div>
          </div>
          <!--Fila1 c Articulos-->
          <div class="row mtop-botton coldresp" *ngIf="articles !== null">
            <div class="col-md-1 text-center iconoResp iconoDollar">
              <br />
              <span class="fa fa-dollar color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-6 textAling text1 PrecioSugerido">
              <br />
              <p class="color-blue">Precio sugerido</p>
            </div>
            <div class="col-md-5 textAling text2 usd divprecio textvehiculoNombre">
              <div class="contenedorPrecioIcono">
                <h5>
                  <strong *ngIf="rayas">- - - -</strong>
                  <strong>{{ precio }}</strong> USD
                  <span class="text-danger abvertenciaprecio" *ngIf="precio == null">*</span>
                  <!--{{ service.vehicle ? service.vehicle.precioTotal : "" }}
                <span class=" abvertenciaprecio ml-3 mr-5"
              *ngIf="precio == null "
              >
             Campo Requerido
              </span> -->
                </h5>
                <!--  textAling usd text2     -->
             
                <!--ICONOS Para articulos-->

                <div *ngIf="ruta" style="justify-content:center; flex:1; padding: -10px; " class="contenedorIcono">
                  <button *ngIf="precio > (totalPrice * 0.7) && precio < (totalPrice * 0.86)"
                    class="fa fa-meh-o btnIconoprecio" style="font-size: 38px; border-color:transparent;" type="button"
                    data-toggle="modal" data-target="#iconoAmarillo"
                    title="El precio sugerido esta un poco por debajo del promedio.Puede Solicitar un Kamgus sin Embargo puede no tener respuesta de ningun conductor"></button>
                  <app-modales-iconos [value]="totalPrice.toFixed(0)"></app-modales-iconos>
                  <button *ngIf="precio >= (totalPrice * 0.86)" class="fa fa-smile-o btnIconoprecio"
                    style="font-size: 38px; border-color:transparent;" type="button" data-toggle="modal"
                    data-target="#iconoVerde"
                    title="El precio sugerido es acorde al promedio. Puede Solicitar un Kamgus es muy posible que un conductor acepte su servicio"></button>
                  <app-modales-iconos2 [value]="totalPrice.toFixed(0)"></app-modales-iconos2>
                  <span *ngIf="precio <= (totalPrice * 0.7)" class="fa fa-frown-o btnIconoprecio"
                    style="font-size: 38px; border-color:transparent;" type="button" data-toggle="modal"
                    data-target="#iconoRojo"
                    title="El precio sugerido esta muy por debajo del promedio.Puede Solicitar un Kamgus sin Embargo es muy posible no tener respuesta de ningun conductor"></span>
                  <app-modales-iconos3 [value]="totalPrice.toFixed(0)"></app-modales-iconos3>
                </div>

                <!--ICONOS-->
              </div>
              <button type="submit" class="btnSugerirPrecio" data-toggle="modal" data-target="#sugerirPrecio"
                id="btnSugerir">
                <h6>SUGERIR PRECIO</h6>
              </button>
              <span class="text-danger ml-3 text-dangerPRECIO" *ngIf="precio == null && dangerPRECIO">
                *Campo Requerido
              </span>
            </div>
          </div>
          <!--modal customize article-->
          <!-- Modal Precio Sugerido -->
          <div class="modal modalSugerirPrecio" id="sugerirPrecio" tabindex="-1">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Precio Sugerido</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body modalPrecio">
                  <form [formGroup]="formDataPreciosg" class="row col-md-10 m-auto">
                    <div class="mt-3 mb-3">
                      <div class="subtiPrecio" style="text-align: center;">
                        <span>Sugiere a nuestros conductores el precio a pagar</span>
                        <input autocomplete="none" type="number" class="form-control" formControlName="PrecioSugerido"
                          placeholder="Precio Sugerido $" />
                      </div>
                      <div style="margin-top: 5px; margin-bottom: 5px;">
                        <!--<span class="texto-totalPrice">Precio Promedio {{ totalPrice.toFixed(0) }} USD</span>-->
                        <span class="form-text text-danger" *ngIf="
                                                formDataPreciosg.controls.PrecioSugerido.errors &&
                                                formDataPreciosg.controls.PrecioSugerido.touched
                                              ">
                          El precio debe ser mayor de cero
                        </span>
                      </div>
                    </div>
                    <button type="button" class="btnmodalPrecio btn btn-primary btn-warning" data-dismiss="modal"
                      (click)="addPrecioSugerido()">
                      <p>Continuar</p>
                    </button>
                    <!--(click)="addPrecioSugerido()"-->

                  </form>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal Capacidad -->
          <!--<div class="modal modalSugerirPrecio" *ngIf="cargaAcept!==false" tabindex="-1">
                  <div class="modal-dialog" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h4 class="modal-title">Error de Capacidad</h4>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body">
                            No disponemos de vehiculo para esa capacidad
                          </div>
                      </div>
                  </div>
              </div>-->

          <!--Fin Modal-->
          <!--Fila1 d-->
          <div class="row mtop-botton coldresp mt-5">
            <div class="col-md-1 text-center iconoResp iconoCuándoLoDesea">
              <span class="fa fa-clock-o color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-6 textAling text1">
              <p class="color-blue">Cuándo lo desea</p>
            </div>
            <div class="col-md-5 textAling text2 textvehiculoNombre">
              <h5>
                {{ service.options.typeProgram === "ACTIVO" ? "AHORA MISMO" : "RESERVADO" }}
              </h5>
            </div>
          </div>
          <!--Fila1 e-->
          <div class="row mtop-botton coldresp">
            <div class="col-md-1 text-center iconoResp">
              <span class="fa fa-automobile color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-6 textAling text1">
              <p class="color-blue">Vehículo seleccionado</p>
            </div>
            <div class="col-md-5 textAling text2 textvehiculoNombre">
              <!-- {{ service.vehicle.app_icon_selected }} -->
              <!-- ORIGINAL <h5>{{ vehicle_nombre | uppercase }}</h5>-->
              <h5 *ngIf="cargaAcept === false">{{ vehicle_nombre | uppercase }}</h5>
              <h5 *ngIf="cargaAcept !== false" style="color: red;">{{ vehicle_nombre | uppercase }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--detalle del servicio-->
    <div class="col-md-6">
      <div class="card card-kg">
        <div class="card-body subtitulo">
          <div class="contenedorRuta">
            <h4>RUTA</h4>
            <div class="btnModificarRuta">
              <button type="button" class="btn waves-effect waves-light btn-rounded btn-secondary" data-toggle="modal"
                data-target="#NuevaRuta">
                <!-- routerLink="/"-->
                MODIFICAR RUTA
              </button>
            </div>

            <!--modal customize article-->

            <div class="modal" id="NuevaRuta" tabindex="-1">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">
                      ¿Estás de acuerdo en modificar ruta?
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body modalruta">
                    <div class="col-md-10 mt-5 mb-3">
                      <span>Al modificarla se cambiarán los puntos de la dirección</span>
                    </div>
                    <button type="button" class="btnmodalPrecio btn btn-primary btn-warning" (click)="modificarRuta()"
                      data-dismiss="modal" aria-label="Close">
                      <p>Continuar</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!--Fin Modal-->
          </div>
          <hr class="separadorsubtitulo" />
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>

            <!--Directions-->
            <agm-direction [origin]="origin" [destination]="destination">
            </agm-direction>
          </agm-map>
        </div>
      </div>
    </div>
  </div>
  <!--FILA 2-->
  <div class="row">
    <!--metodos de pago-->
    <div class="col-md-6">
      <div class="card card-kg">
        <div class="card-body subtitulo metodopagotext">
          <h4>MÉTODOS DE PAGO</h4>
          <hr class="separadorsubtitulo" />

          <div class="row mtop-botton click coldresp" [ngClass]="{
              'vehicle-selected': service.options.typePay === 'credito'
            }" (click)="changeMethodPay('credito')">
            <div class="col-md-1 iconoResp">
              <span class="fa fa fa-credit-card-alt color-orange"
                style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <!-- Opción de pago con Tarjeta -->
            <div class="col-md-5 text-responsive text-responsive-card">
              <p class="mb-1">TARJETA DE CRÉDITO</p>
              <p class="m-0" *ngIf="card_number.length > 0">{{card_number}}</p>
            </div>

            <!--Tarjeta de credito subtitulo-->
            <!--

                                      <div class="col-md-6 tarjetaOculta" *ngIf="service.options.typePay === 'credito'">
                                        <div class="contenbotontarjetas">
                                          <button type="button" class="
                                            btn
                                            waves-effect waves-light
                                            btn-rounded btn-warning btn-small
                                            btnAgregarTarjeta
                                            " data-toggle="modal" data-target="#addCard" (click)="beginAddCard()">
                                            Agregar <wbr/> Tarjeta
                                          </button>
                                        </div>
                                      </div>
                                    -->
          </div>
          <!-- Opción de pago en Efectivo -->
          <div class="row mtop-botton click coldresp" [ngClass]="{
              'vehicle-selected': service.options.typePay === 'efectivo'
            }" (click)="changeMethodPay('efectivo')">
            <div class="col-md-1 iconoResp">
              <span class="fa fa-usd color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-11 text-responsive">
              <p>Efectivo</p>
            </div>
          </div>
          <!-- Opción de pago con Yappy -->
          <div class="row mtop-botton click coldresp" [ngClass]="{
              'vehicle-selected': service.options.typePay === 'yappy'
            }" (click)="changeMethodPay('yappy')">
            <div class="col-md-1 iconoResp">
              <span class="fa fa-exchange color-orange" style="font-size: 24px; border-color:transparent;"></span>
            </div>
            <div class="col-md-11 text-responsive">
              <p>Yappy (&#64;kamgus)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--row 3-->
    <!--<div class="col-md-6" *ngIf="articles === null">-->
    <div class="col-md-6" *ngIf="typeVehicle === true">
      <div class="card card-kg">
        <div class="card-body subtitulo texarea">
          <h4>
            <span class="text-danger abvertenciaprecio" *ngIf="imfoInvitado.controls.descripcion.value === ''">*</span>
            <!--DESCRIPCIÓN DE LA ORDEN-->
            Describe la carga en detalle para el conductor
          </h4>
          <hr class="separadorsubtitulo" />
          <div class="mb-2">
            <button
              type="button"
              class="btn btn-rounded btn1"
              (click)="cotizar()"
            >
              <span>COTIZAR POR ARTÍCULO</span>
            </button>
          </div>
          <div [formGroup]="imfoInvitado" class="form-group">
            <textarea class="form-control2" rows="5" formControlName="descripcion" maxlength="320"
              placeholder="Tamaño y tipo de carga (proporciona más detalle para el conductor)"></textarea>
            <p class="m-0">
              <span class="descripcionavbertencia text-danger" *ngIf="
                imfoInvitado.controls.descripcion.errors &&
                imfoInvitado.controls.descripcion.touched
              ">
              Este Campo no debe estar vacio
            </span>
            </p>
            <label [class]="'btn btn-secondary btn-block col-xs-12 '+(imfoInvitado.controls.assistant.value == 1 ? 'active' : '')">
              <input type="checkbox" autocomplete="off" formControlName="assistant" value="1"> Necesito ayudante: {{imfoInvitado.controls.assistant.value == 1 ? 'Si': 'No'}}
            </label>
            <div>
              <div class="col-sm-6 col-md-2 px-0 mb-1" *ngFor="let previewImage of previewImages">
                <img [src]="previewImage" alt="" *ngIf="previewImage && previewImage !== ''" class="img-thumbnail">
              </div>
              <label for="image_description" class="btn btn-secondary btn-block col-xs-12">
                Seleccionar fotos
                <input id="image_description" class="d-none" type="file" (change)="showPreview($event)" accept="file_type | image/* | media_type" multiple>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--row 4-->
    <!--<div class="col-md-6" *ngIf="articles !== null">-->
    <div class="col-md-6" *ngIf="typeVehicle === false">
      <div class="card card-kg">
        <div class="card-body subtitulo">
          <div class="d-flex flex-row justify-content-between">

            <h4>ARTICULOS</h4>
            <div class="btnAgregarArticulos">
              <button type="button" class="btn btn-rounded btn-block btn1" (click)="cotizar('vehiculo')">
                <span>COTIZAR POR VEHICULO</span>
              </button>
              <button type="button" class="btn waves-effect waves-light btn-rounded btn-block btn-secondary"
                (click)="goToAddMoreArticles()">
                <!-- routerLink="/"-->
                Agregar más articulos
              </button>

            </div>
          </div>
          <hr class="separadorsubtitulo" />
          <div class="row" *ngFor="let item of articles; let i = index">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-9">
                      <img [hidden]="item.img == ''" [src]="item.img" style="width: 22px; height: 22px" />
                      <span [hidden]="item.name == ''">{{ item.nombre }}</span>
                      <span [hidden]="item.nombre == ''">{{ item.name }}</span>
                    </div>
                    <div class="col-md-3">
                      <strong class="color-blue" style="text-decoration: underline">
                        {{ item.cantidad }}</strong>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-center">
                  <div class="rowiconos">
                    <div class="col-md-4 click">
                      <span class="fa fa-plus-circle color-blue" (click)="addQuatity(i)"></span>
                    </div>
                    <div class="col-md-4 click">
                      <span class="fa fa-minus-circle color-blue" (click)="removeQuatity(i)"></span>
                    </div>
                    <div class="col-md-4 click">
                      <span class="fa fa-trash-o color-blue" (click)="delete(item)"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--row 4 Fin-->
  </div>
  <!--FORMULARIO INVITADO-->

  <div class="contenedorForm subtitulo" *ngIf="articles === null">
    <h4>INFORMACIÓN USUARIO INVITADO</h4>
    <hr />

    <form [formGroup]="imfoInvitado" (submit)="sendArticulos()" class="formularioSt">
      <!-- <form [formGroup]="imfoInvitado" (submit)="send()" class="formularioSt"> -->
      <div class="CorreoTelefono">
        <div class="Correo">
          <input placeholder="Nombre" class="form-control" type="text" name="nombre" formControlName="nombre"
            (input)="inputValidator($event)" />
          <span class="text-danger" *ngIf="
              imfoInvitado.controls.nombre.errors &&
              imfoInvitado.controls.nombre.touched
            ">
            Campo Requerido
          </span>
        </div>
        <div class="Telefono">
          <div class="casilla">
            <input placeholder="Apellido" class="form-control" type="text" name="apellido" formControlName="apellido"
              (input)="inputValidator($event)" />
            <span class="text-danger" *ngIf="
                imfoInvitado.controls.apellido.errors &&
                imfoInvitado.controls.apellido.touched
              ">
              Campo Requerido
            </span>
          </div>
        </div>
      </div>

      <br />

      <div class="CorreoTelefono">
        <div class="Correo">
          <input placeholder="Email" class="form-control" type="email" name="email" formControlName="correo" />
          <span class="text-danger" *ngIf="
              imfoInvitado.controls.correo.errors &&
              imfoInvitado.controls.correo.touched
            ">
            Campo Requerido
          </span>
          <a class="text-danger" *ngIf="emailRegistrado" [href]="loginUrl+'?'+loginParams">
            Email Registrado Ingrese a su cuenta
          </a>
        </div>

        <div class="Telefono">
          <!--
        <input
          id="text1"
          placeholder="+507----------------------------------"
          class="form-control solo-numero"
          type="text"
          name="telefono"
          onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;"
          maxlength="13"

          formControlName="telefono"
          [textMask]="{mask:phoneMask}"
        />-->
          <input class="form-control solo-numero" type="text" name="telefono"
            placeholder="+507----------------------------------" mask="0000-0000" prefix="+507 "
            formControlName="telefono" />

          <span class="text-danger" *ngIf="
              imfoInvitado.controls.telefono.errors &&
              imfoInvitado.controls.telefono.touched
            ">
            Campo Requerido
          </span>
        </div>
      </div>
      <br />
      <div class="CorreoTelefono" *ngIf="imfoInvitado.controls.crearCuenta.value">
        <div class="Correo">
          <input placeholder="Contraseña" class="form-control" type="password" name="password"
            formControlName="clave" />
          <span class="text-danger" *ngIf="
              imfoInvitado.controls.clave.errors &&
              imfoInvitado.controls.clave.touched
            ">
            Campo Requerido
          </span>
        </div>

        <div class="Telefono">
          <input placeholder="Confirmar Contraseña" class="form-control" type="password" name="passwordConfirm"
            formControlName="claveConfirm" />
          <span class="text-danger" *ngIf="
              imfoInvitado.controls.claveConfirm.errors &&
              imfoInvitado.controls.claveConfirm.touched
            ">
            Campo Requerido
          </span>
        </div>
      </div>
      <br />

      <!--checbox-->
      <div class="inputTerminosCondiciones">
        <p class="Condiciones">
          <input class="custom-control-input" id="check1" type="checkbox" formControlName="condiciones" />
          <label class="custom-control-label" for="check1"></label>
          <a href="https://kamgus.cf/terminos-y-condiciones-empresa/" target="blank">
            Acepto los términos y condiciones
          </a>

          <span class="text-danger ml-3" *ngIf="
              imfoInvitado.controls.condiciones.errors &&
              imfoInvitado.controls.condiciones.touched
            ">
            Debe aceptar los términos y condiciones
          </span>
        </p>

        <!--<p>
          <input
            class="inputSummary"
            id="check2"
            type="checkbox"
            formControlName="crearCuenta"
          />
          <label for="check2"
            >Quiero crear cuenta y hacer seguimiento de mis servicios</label
          >
        </p>-->
      </div>

      <div class="contenedoresBtnCompra">
        <div class="btnmargin">
          <button type="button" (click)="cancelar()" class="
              btn
              waves-effect waves-light
              btn-rounded btn-danger btn-small
              btnCancelar
            ">
            <p>Cancelar</p>
          </button>
        </div>
        <div class="ml-2 btnmargin">
          <button class="
              btn
              waves-effect waves-light
              btn-rounded btn-warning btn-small
              btnSolicitar
            ">
            <p>Solicitar Kamgus</p>
          </button>
        </div>
      </div>
      <div class="contenedorAbvertencia">
        <span class="text-danger ml-3 abvertenciaFinal" *ngIf="
            (imfoInvitado.controls.descripcion.errors &&
              imfoInvitado.controls.descripcion.touched) ||
            (imfoInvitado.controls.nombre.errors &&
              imfoInvitado.controls.nombre.touched) ||
            (imfoInvitado.controls.apellido.errors &&
              imfoInvitado.controls.apellido.touched) ||
            (imfoInvitado.controls.telefono.errors &&
              imfoInvitado.controls.telefono.touched) ||
            (precio == null && dangerPRECIO)
          ">
          * VERIFIQUE CAMPOS REQUERIDOS
        </span>
        <!--
     <span class="text-danger ml-3 abvertenciaFinal"
    *ngIf="
    precio == null
  ">
      * PRECIO SUGERIDO REQUERIDO
   </span>
     data-toggle="modal"
          data-target="#pedido"
  -->
      </div>
    </form>
    <!--FORMULARIO INVITADO FIN-->
  </div>

  <!--FORMULARIO INVITADO Articulo-->

  <div class="contenedorForm subtitulo" *ngIf="articles !== null">
    <h4>INFORMACIÓN USUARIO INVITADO</h4>
    <hr />

    <form [formGroup]="imfoInvitadoArticulos" (submit)="sendArticulos()" class="formularioSt">
      <div class="CorreoTelefono">
        <div class="Correo">
          <input placeholder="Nombre" class="form-control" type="text" name="nombre" formControlName="nombre"
            (input)="inputValidator($event)" />
          <span class="text-danger" *ngIf="
              imfoInvitadoArticulos.controls.nombre.errors &&
              imfoInvitadoArticulos.controls.nombre.touched
            ">
            Campo Requerido
          </span>
        </div>
        <div class="Telefono">
          <div class="casilla">
            <input placeholder="Apellido" class="form-control" type="text" name="apellido" formControlName="apellido"
              (input)="inputValidator($event)" />
            <span class="text-danger" *ngIf="
                imfoInvitadoArticulos.controls.apellido.errors &&
                imfoInvitadoArticulos.controls.apellido.touched
              ">
              Campo Requerido
            </span>
          </div>
        </div>
      </div>

      <br />

      <div class="CorreoTelefono">
        <div class="Correo">
          <input placeholder="Email" class="form-control" type="email" name="email" formControlName="correo" />
          <span class="text-danger" *ngIf="
              imfoInvitado.controls.correo.errors &&
              imfoInvitado.controls.correo.touched
            ">
            Campo Requerido
          </span>
          <a class="text-danger" *ngIf="emailRegistrado" [href]="loginUrl+'?'+loginParams">
            Email Registrado Ingrese a su cuenta
          </a>
        </div>
        <div class="Telefono">
          <!--<input
          id="text1"
          placeholder="+507----------------------------------"
          class="form-control solo-numero"
          type="text"
          name="telefono"
          onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;"
          maxlength="13"

          formControlName="telefono"

        />-->

          <input class="form-control solo-numero" type="text" name="telefono"
            placeholder="+507----------------------------------" mask="0000-0000" prefix="+507 "
            formControlName="telefono" />

          <span class="text-danger" *ngIf="
              imfoInvitadoArticulos.controls.telefono.errors &&
              imfoInvitadoArticulos.controls.telefono.touched
            ">
            Campo Requerido
          </span>
        </div>
      </div>
      <br />
      <div class="CorreoTelefono" *ngIf="imfoInvitadoArticulos.controls.crearCuenta.value">
        <div class="Correo">
          <input placeholder="Contraseña" class="form-control" type="password" name="password"
            formControlName="clave" />
          <span class="text-danger" *ngIf="
              imfoInvitadoArticulos.controls.clave.errors &&
              imfoInvitadoArticulos.controls.clave.touched
            ">
            Campo Requerido
          </span>
        </div>

        <div class="Telefono">
          <input placeholder="Confirmar Contraseña" class="form-control" type="password" name="passwordConfirm"
            formControlName="claveConfirm" />
          <span class="text-danger" *ngIf="
              imfoInvitadoArticulos.controls.claveConfirm.errors &&
              imfoInvitadoArticulos.controls.claveConfirm.touched
            ">
            Campo Requerido
          </span>
        </div>
      </div>
      <br />

      <div class="inputTerminosCondiciones">
        <p class="Condiciones">
          <input class="custom-control-input" id="check1" type="checkbox" formControlName="condiciones" />
          <label class="custom-control-label" for="check1"></label>
          <a href="https://kamgus.cf/terminos-y-condiciones-empresa/" target="blank">
            Acepto los términos y condiciones
          </a>

          <span class="text-danger ml-3" *ngIf="
              imfoInvitadoArticulos.controls.condiciones.errors &&
              imfoInvitadoArticulos.controls.condiciones.touched
            ">
            Debe aceptar los términos y condiciones
          </span>
        </p>
        <!--<p>
          <input
            class="inputSummary"
            id="check2"
            type="checkbox"
            formControlName="crearCuenta"
          />
          <label for="check2"
            >Quiero crear cuenta y hacer seguimiento de mis servicios</label
          >
        </p>-->
      </div>

      <div class="contenedoresBtnCompra">
        <div class="btnmargin">
          <button type="button" (click)="cancelar()" class="
              btn
              waves-effect waves-light
              btn-rounded btn-danger btn-small
              btnCancelar
            ">
            <p>Cancelar</p>
          </button>
        </div>
        <div class="ml-2 btnmargin">
          <button class="
              btn
              waves-effect waves-light
              btn-rounded btn-warning btn-small
              btnSolicitar
            ">
            <p>Solicitar Kamgus</p>
          </button>
        </div>
      </div>
      <div class="contenedorAbvertencia">
        <span class="text-danger ml-3 abvertenciaFinal" *ngIf="
            (imfoInvitadoArticulos.controls.apellido.errors &&
              imfoInvitadoArticulos.controls.apellido.touched) ||
            (imfoInvitadoArticulos.controls.nombre.errors &&
              imfoInvitadoArticulos.controls.nombre.touched) ||
            (imfoInvitadoArticulos.controls.telefono.errors &&
              imfoInvitadoArticulos.controls.telefono.touched) ||
            (precio == null && dangerPRECIO)
          ">
          * VERIFIQUE CAMPOS REQUERIDOS
        </span>
      </div>
    </form>
  </div>

  <!--FIN FORMARTICULOS-->

  <br />
  <div class="piePagina"><app-footer></app-footer>-</div>
</div>
<!--
   class="btn waves-effect waves-light btn-rounded btn-warning btn-small btn-block btnSolicitar"
    data-toggle="modal"
        data-target="#pedido"

        data-toggle="modal"
        data-target="#pedido"
    routerLink="/"
-->
<!--modales-->

<div class="modal fade" id="addCard" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel1">Agregar Tarjeta</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--Componente modal con formulario para agregar una tarjeta asociada a un customer-->
      <app-add-card *ngIf="stripeClientSecret.client_secret" [client_secret]="stripeClientSecret.client_secret"
        (onCardSaved)="onCardSaved($event)"></app-add-card>
    </div>
  </div>
</div>

<!--Gracias por su compra-->

<div class="modal modalPedido" tabindex="-1" id="pedido">
  <div class="modal-dialog" role="document">
    <div class="modal-content GraciasPedido">
      <button type="button" class="close closebtn" data-dismiss="modal" aria-label="Close" routerLink="/">
        <span aria-hidden="true">&times;</span>
      </button>
      <!--CONTENIDO-->
      <div class="text-center contenedorPedido">
        <br /><br />
        <div class="modal-headerbtn">
          <h1 class="color-orange titulo">GRACIAS POR TU PEDIDO</h1>
        </div>

        <br />
        <i class="fa fa-shopping-bag"></i>
        <br />
        <br />
        <br />
        <br />
        <h3>Enviaremos actualizacion de pedido por email</h3>
        <h3 class="color-orange">SU PEDIDO ESTA EN PROCESO</h3>
        <h4>#45246258</h4>
        <br />
        <P class="textgracias">MEJORA LA EXPERIENCIA AL UTILIZAR KAMGUS CON NUESTRAS APP MOVILES</P>
        <div class="row rowrespondiveModal">
          <div class="col-md-6 text-right coldrespondiveModal">
            <a>
              <img src="../../../assets/images/google.png" style="height: 40px" />
            </a>
          </div>
          <div class="col-md-6 text-left coldrespondiveModal">
            <a>
              <img src="../../../assets/images/ios.png" style="height: 40px" />
            </a>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</div>