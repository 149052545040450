import { Component, OnInit } from '@angular/core';

declare var jQuery:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //public registerLink = "https://app.kamgus.com/register-user";
  public registerLink = "#";
  public linkTarget = "_self";
  public appleLink = "https://apps.apple.com/us/app/kamgus-usuario/id1495896386";
  public androidLink = "https://play.google.com/store/apps/details?id=com.kamgusu.pa";

  constructor() { }

  ngOnInit(): void {
    if(navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)){
      //link to apple store
      this.registerLink = this.appleLink;
      this.linkTarget = "_blank";
    }else if(navigator.userAgent.match(/Android/i)){
      //link to play store
      this.registerLink = this.androidLink;
      this.linkTarget = "_blank";
    
    }
  }
  showModal(e: Event){
    e.preventDefault();
    if (this.linkTarget != "_blank") {
      jQuery("#platformModal").modal('show');
    }
  }
}
