<app-header></app-header>

<div class="ContenedorPointToPoint">
    <!--Mapa-->
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" (click)="actived = false">
        <agm-marker [latitude]="lat" [longitude]="lng" *ngIf="locationMap"></agm-marker>

        <!--Directions-->
        <agm-direction [origin]="origin" [destination]="destination" *ngIf="!locationMap">
        </agm-direction>
    </agm-map>

    <div [class]="'card card-services formMap '+(actived ? 'active' : '')" [ngClass]="{'schedule': tipoProgramacion === 'AGENDADO'}">
        <div class="card-title-k" (click)="actived = !actived">CREA UN KAMGUS</div>
        <div class="row card-body">
            <div class="col-md-12 mt">
                <div class="input-group">
                    <span class="fa fa-map-marker color-orange icono2"></span>

                    <input [(ngModel)]="inicio" autocomplete="none" ngx-google-places-autocomplete [options]="options"
                        class="form-control" id="txtInicio" country="us" #placesRef="ngx-places" placeholder="Dirección de inicio" autofocus
                        (onAddressChange)="handleAddressChange($event)" />

                    <span class="far fa-trash-alt color-orange trash-icon-service trash" (click)="clearStart()"></span>
                </div>
            </div>

            <div class="col-md-12 mt">
                <div class="input-group">
                    <span class="fa fa-flag color-orange icono2"></span>

                    <input [(ngModel)]="fin" autocomplete="none" class="form-control" id="txtFin" ngx-google-places-autocomplete
                        [options]="options" #placesRef="ngx-places" placeholder="Dirección de entrega"
                        (onAddressChange)="handleAddressChange2($event)" />

                    <span class="far fa-trash-alt color-orange trash-icon-service trash" (click)="clearEnd()"></span>
                </div>
            </div>

            <!--SELECT COTIZAR POR-->
            <!--
            <div class="col-md-12 mt">
                <div class="input-group select">
                    <span class="fa fa-file-text-o color-orange icono1"></span>

                    <select placeholder="Dirección entrega" [(ngModel)]="tipoVehiculo" id="selTipoVehiculo">
                        <span class="input-group-addon" id="basic-addon1"> </span>
                        <option value="articulo">Cotizar por artículo</option>
                        <option value="vehiculo">Cotizar por vehículo</option>
                    </select>
                </div>
            </div>
            -->
            <!--SELECT COTIZAR POR FIN-->

            <!--SELECT PAGO-->
            <!--

                <div class="col-md-12 mt">
                    <div class="input-group select">
                        <span class="fa fa-dollar color-orange icono1"></span>
                        <select placeholder="Dirección entrega" [(ngModel)]="tipoPago">
                            <span class="input-group-addon" id="basic-addon1"> </span>
                            
                            <option value="efectivo">Efectivo</option>
                            <option value="credito">Tarjeta de crédito</option>
                            <option value="efectivo">Yappy</option>
                        </select>
                    </div>
                </div>
            -->

            <!--SELECT PAGO FIN-->

            <!--SELECT PROGRAMAR-->

            <div class="col-md-12 mt">
                <div class="input-group select">
                    <span class="fa fa-clock-o color-orange icono1"></span>
                    <select placeholder="Dirección entrega" [(ngModel)]="tipoProgramacion" id="selTipoProgramacion">
                        <span class="input-group-addon" id="basic-addon1"> </span>

                        <option value="ACTIVO">Lo quiero ahora mismo</option>
                        <option value="AGENDADO">Quiero programar</option>
                        <!--
        <option value="ahora">Lo quiero ahora mismo</option>
        <option value="reserva">Quiero programar</option>
      -->
                    </select>
                </div>
            </div>

            <!--SELECT PROGRAMAR FIN-->

            <div class="col-md-12 mt" *ngIf="tipoProgramacion === 'AGENDADO'">
                <div class="input-group">
                    <span class="input-group-addon" id="basic-addon1">
                        <span class="fa fa-clock-o color-orange"></span>
                    </span>
                    <input class="form-control" type="datetime-local" value="dd/mm/aaaa -:-" [(ngModel)]="now" [min]="minDate"
                        id="example-datetime-local-input" (change)="validateDate($event)"/>
                </div>
            </div>

            <div class="col-md-12 mt">
                <button type="button" class="btn waves-effect waves-light btn-rounded btn-warning btn-block py-3"
                (click)="next()" [disabled]="
                inicio === '' ||
                fin === '' ||
                tipoPago === 'tp' ||
                inicio === fin ||
                verificar === false
                ">
                COTIZAR SERVICIO
            </button>
            </div>
            <div class="col-md-12 mt">
                <p class="m-0 text-center">
                    Versión: 
                    <span style="
                        font-size: 14px;
                        text-align: left;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.6);
                    ">
                    {{version}}
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>
