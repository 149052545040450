<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar menuPrincipal">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
      <a class="navbar-brand" href="index.html">
        <img
          src="assets/images/logo-white2.png"
          class="light-logo img-fluid"
          alt="homepage"
        />
      </a>
      <!-- Boton
                <ul class="navbar-nav mr-auto">
                This is 
                <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li> 
            </ul>-->
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0">
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item hidden-xs-down search-box"> <a class="nav-link hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-search"></i></a>
                    <form class="app-search">
                        <input type="text" class="form-control" placeholder="Search & enter"> <a class="srh-btn"><i class="ti-close"></i></a> </form>
                </li> -->
        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle waves-effect waves-dark circulo"
            href=""
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi mdi-account"></i>
            <!--<img src="assets/images/users/1.jpg" alt="user" class="profile-pic" />-->
          </a>
          <div class="dropdown-menu dropdown-menu-right animated flipInY">
            <ul class="dropdown-user">
              <li>
                <div class="dw-user-box">
                  <div class="u-img circulob">
                    <i class="mdi mdi-account"></i>
                    <!--<img src="assets/images/users/1.jpg" alt="user" />-->
                  </div>

                  <div class="u-text">
                    <h4>Invitado</h4>
                    <!-- <p class="text-muted">Email</p> -->
                    <!--  <h4>{{ user.nombre }} {{ user.apellidos }}</h4>
                                        <p class="text-muted">{{ user.email }} </p>-->
                  </div>
                </div>
              </li>
              <li role="separator" class="divider"></li>
              <!--<li><a routerLink="/profile"><i class="ti-user"></i> Perfil</a></li>-->
              <!--
              <li>
                <a href="https://app.kamgus.com"
                  ><i class="ti-user"></i> Iniciar Sesión</a
                >
              </li>-->
              <!-- <li><a routerLink="/wallet/method-payment"><i class="ti-wallet"></i> Billetera</a></li>-->
              <li class="menu__platform_links">
                <!--
                <a
                  [href]="registerLink"
                  [target]="linkTarget"
                  (click)="showModal($event)"
                  ><i class="ti-wallet"></i> Registrarse</a
                >-->
                <a
                  [href]="registerLink"
                  [target]="linkTarget"
                  (click)="showModal($event)"
                  ><i class="ti-wallet"></i> Descargar</a
                >
                  <ul>
                    <li class="platform_links">
                      <a [href]="androidLink" target="_blank">
                        <img src="../../../assets/images/01_google-play.png" width="120" alt="" class="img-thumbnail" />
                      </a>
                      <a [href]="appleLink" target="_blank">
                        <img src="../../../assets/images/02_app-store.png" width="120" alt="" class="img-thumbnail" />
                      </a>
                    </li>
                  </ul>
                <!-- 
                <select placeholder="Registrarse">
                  <option>Empresa</option>
                  <option>Usuario</option>
               
                </select>
               -->
              </li>

              <li role="separator" class="divider"></li>

              <!-- <li><a href="#" (click)="logOut()"><i class="fa fa-power-off"></i> Salir</a></li>-->
            </ul>
          </div>
        </li>
      </ul>
      <!-- Modal Precio Sugerido -->
      <div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" id="platformModal" aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Descarga nuestra aplicación de usuario</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
              <a [href]="androidLink" target="_blank">
                <img src="../../../assets/images/01_google-play.png" alt="" class="img-thumbnail" />
              </a>
              <a [href]="appleLink" target="_blank">
                <img src="../../../assets/images/02_app-store.png" alt="" class="img-thumbnail" />
              </a>

          </div>
        </div>
      </div>
      
    </div>
  </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->
