import { Component, OnInit } from '@angular/core';
import {timer} from 'rxjs'
import { Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void {


    const espera = timer(1200);
      

    espera.subscribe((n)=> {
      this.show=false
    });

    /*this._router.events.subscribe(event => {
      if (event instanceof NavigationStart){
        this.show=true;
      } else if (event instanceof NavigationEnd) {
        this.show=false
      }
    })*/
  }

  show=true

}
