import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { StripeService } from 'src/app/services/StripeService/stripe-service.service';
import { ApiService } from 'src/app/servicesApi/api/api.service';
import { SharedService } from '../list-vehicle/SharedService/shared.service';
import { environment } from 'src/environments/environment';
import { clearServiceData } from 'src/app/shared/helpers/helpers';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {
  id: any;

  private sub: any;
  driverSelected:any;
  constructor(private route: ActivatedRoute, private _api: ApiService, private stripeService: StripeService, private _sh: SharedService) {  }

  ngOnInit(): void {
    this.driverSelected = JSON.parse(localStorage.getItem("driver") || "{}");
    this.sub = this.route.queryParams.subscribe(params => {
      const serviceId = params['sid'] || localStorage.getItem('id');
      this.id = serviceId;

      // In a real app: dispatch action to load the details here.
      console.log("params: ", params);
      //Evalua si la transaccion (por tarjeta) ha sido finalizada exitamente, para continuar con la activacion del servicio
      if(params['payment_type'] == "credito"){
        this.driverSelected = params['did'] ? {conductor_id: params['did']} : this.driverSelected;
        this.stripeService.refreshCheckoutSessionStatus({
          idservicio: serviceId,
        }).subscribe((resp: any) => {
          console.log(resp)
          if(resp.data && (resp.data.status == "complete" || resp.data.status == "succeeded")){
            if(this.driverSelected.conductor_id){
              this.sendAfterPayment(this.driverSelected);
            }
          }
        }, (err: any) => {
          console.log(err)
          if(err.status === 404){
            this._sh.showError(err.error.msg, "Error");
          }
        });
        
      }
   });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  //Envia info del conductor seleccionado para el servicio e iniciarlo
  sendAfterPayment(driver: any){
    const {vehiculos_id, conductor_id, precio, registro} = driver;
      let body: any = {
        conductores_id: conductor_id,
        servicios_id: localStorage.getItem('id'),
        vehiculos_id: vehiculos_id,
        precio: precio,
        role: registro,

      };
      /*
      if(this.service.options.typePay == "credito"){
        body.customer_id = (this.service.options.paymentData as PaymentCustomer).customer_id;
        body.payment_method = (this.service.options.paymentData as PaymentCustomer).payment_method;
      }*/
      const dataToSend = new FormData();
      for (var key in body) {
        dataToSend.append(key, body[key]);
      }
      console.log('Vehiculo ID ------', vehiculos_id);

      this._api
        .genericPut(
          `${environment.endpoints.services.confirm_driver}`,
          //'http://www.api.kamgus.com/usuario-v2/index.php/kamgus/postServConfir',
          //'http://www.api.kamgus.com/v2/general/postServicioAceptado',
          {
            driver_id: conductor_id,
            servicio_id: localStorage.getItem('id'),
            vehiculos_id: vehiculos_id,
            precio: precio,
            role: registro,

          }
        )
        .subscribe(
          (resp: any) => {
            //this.msg = resp.text;

            if (!resp.error) {
              //this.msg = resp.text;
              console.log(resp.text);
              clearServiceData();
              localStorage.setItem("thanks_confirmed", "Y");
              if(resp.data && resp.data.url){
                
              }
              window.close();
            }
          },
          (err) => {
            // console.log('err');
            console.log(err);
          }
        );

  }

}
