<!-- TRATANDO DE COLOCAR EN FILA <div class="drivers-container flex-container" >-->
<div class="drivers-container" >
  
    <div class="row rowDrivers">
      <!--Card 1-->
      <div class="col-md-12">
          <div class="colDrivers card">
              <div class="fila1">
                  <div class="icono">
                      <span class="fa fa-clock-o color-orange" style="font-size: 24px"></span>
                  </div>
                  <div class="texto">
                      <p><span>Hora de llegada</span></p>
                  </div>
                  <div class="tiempo">
                      <h5 class="mb-0 py-1">{{ info.tiempo }}</h5>
                  </div>
              </div>
              <div class="fila2">
                  <div class="asesorPersonalContenedor">
                      <div class="asesorPersonalImg">

                          <div class="avatar">
                            <img src="{{ data[0].fotoConductor }}" class="circular--landscape" *ngIf="data[0].fotoConductor; else Avatar" />

                          </div>
                          <ng-template #Avatar class="default-avatar">
                              <i class="mdi mdi-account"></i>
                          </ng-template>
                          <!--

                              <div class="icono2">
                                  <span [class]="'fa fa-star '+(info.rating >= 1 ? 'color-orange' : 'menos')" style="font-size: 20px"></span>
                                  <span [class]="'fa fa-star '+(info.rating >= 2 ? 'color-orange' : 'menos')" style="font-size: 20px"></span>
                                  <span [class]="'fa fa-star '+(info.rating >= 3 ? 'color-orange' : 'menos')" style="font-size: 20px"></span>
                                  <span [class]="'fa fa-star '+(info.rating >= 4 ? 'color-orange' : 'menos')" style="font-size: 20px"></span>
                                  <span [class]="'fa fa-star '+(info.rating >= 5 ? 'color-orange' : 'menos')" style="font-size: 20px"></span>
                                </div>
                            -->
                      </div>
                      <div class="asesorPersonalTexto">
                          <h4>{{ data[0].nombre }} {{ data[0].apellidos }}</h4>
                          <h5>{{ data[0].nombre_camion }}</h5>
                          <h5 *ngIf="data[0].placa">Placa: {{ data[0].placa }}</h5>
                          <!-- <h6>Panamá Centro</h6> -->
                          <p>Precio: {{ info.precio }} USD</p>
                      </div>
                  </div>
              </div>
              <div class="fila3">
                  <button type="button" class="
                      btn
                      waves-effect waves-light
                      btn-rounded btn-small
                      text-center
                      btnAceptar
                      " (click)="enviar(data[0].vehiculos_id, data[0].conductor_id, data[0].precio, data[0].registro)">
                      <p><span>ACEPTAR</span></p>
                  </button>
              </div>
              <div class="fila4">
                <!-- <div class="color1"></div> -->
                    <div class="color2">
                        <app-progress-bar 
                            (complete)="control($event)" 
                            [tinit]="data[0].created_at" 
                            [flag]="flag"
                            [tTimeZone]="'UTC'" 
                            [timer]="true">
                            </app-progress-bar>
                    </div>
              </div>
          </div>
      </div>
    </div>

</div>
